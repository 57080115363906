@import url(https://rsms.me/inter/inter.css);
@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    font-family: 'Inter', sans-serif;
}

body {
    background-color: rgb(37, 37, 37);
    color: #fff;
}

* {
    box-sizing: border-box;
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

.menuWrapper a {
    cursor: pointer;
}

.details a {
    cursor: pointer;
}

.project {
    cursor: default;
}

.menuWrapper {
    position: fixed;
    margin: auto;
    display: inline-block;
    padding-top: 2%;
    padding-left: 26.5%;
    height: 60px;
    width: 100%;
    background-color: rgb(37, 37, 37, 0.25);
    /*display: flex;
    background-color: none;
    height: 60px;
    align-items: center;
    justify-content: center;*/
}

.menuitem {
    justify-self: center;
    text-align: center;
}

.menuWrapper ul,
li {
    padding: 0px;
    text-decoration: none;
    display: inline-block;
    color: #9F9F9F;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
}

.space {
    padding-right: 99.7px;
}

.selected {
    border-bottom: 0.2em #FF0513 solid;
    padding-bottom: 1.3em;
}


/********* HomePage **************/

.homeContainer {
    display: flex;
    flex-direction: column;
    /* background-image: url('../src/img/Desktop.svg');
    background-position: top;
    background-size: cover;*/
    background-color: rgb(37, 37, 37);
    width: 100%;
    height: 100vh;
}

.contentWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    padding: 0 2.5em;
}

.orange {
    color: #FF6F00;
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 400;
    -webkit-animation-name: fadeIn;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 3s;
    /* Safari 4.0 - 8.0 */
    animation-name: fadeIn;
    animation-duration: 3s;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
}


/* Safari 4.0 - 8.0 */

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}


/* Standard syntax */

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 100;
    }
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    100% {
        -webkit-transform: translateY(-10px);
                transform: translateY(-10px);
    }
}

.leftAside {
    justify-self: center;
    color: white;
}

.homeContainer .leftAside {
    background-image: url(/static/media/PATTERN.2f8acd0d.svg);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 40%;
}

.leftAside h1 {
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-size: 80px;
    line-height: 70px;
    padding-bottom: 12px;
    -webkit-animation-name: fadeIn;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 5s;
    /* Safari 4.0 - 8.0 */
    animation-name: fadeIn;
    animation-duration: 5s;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
}

.leftAside h5 {
    font-family: 'Poppins';
    font-size: 1em;
    padding-bottom: 12px;
    -webkit-animation-name: fadeIn;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 5s;
    /* Safari 4.0 - 8.0 */
    animation-name: fadeIn;
    animation-duration: 5s;
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
}

.leftAside p {
    font-family: 'inter';
    font-size: 12px;
    font-weight: 200;
}

.white {
    color: #fff;
}

.contact {
    background: none;
    color: white;
    border: 1px white solid;
    border-radius: 5px;
    font-size: 0.6em;
    padding: 1em;
    width: 8em;
    font-family: 'Lato';
}

.homeContainer .contact {
    margin-top: 12px;
}

button {
    outline: none;
}

.textFlip {}

.rightAside {
    color: white;
    width: 100%;
}

.rightAside p {
    font-family: 'poppins';
    font-size: 1.2em;
    text-align: right;
}

.smallSpace {
    padding-right: 0.5em;
}

.footer {
    display: flex;
}

.downArrow {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
}

.socialNav {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1%;
    padding: 0 2.5em;
    width: 100%;
}

.socialNav p {
    color: #9F9F9F;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    padding-bottom: 10px;
}


/*
.socialIcons a {
    color: #fff;
}


.socialIcons {
    display: flex;
    justify-content: center;
    justify-self: center;
}
*/

.socialIcons {
    margin: auto;
    display: inline;
    text-align: center;
}

.socialNav img {
    width: 20%;
    text-align: center;
    padding-left: 12px;
}

.scrollDown {
    color: #fff;
    display: flex;
    padding: 1em;
}

.scrollDown,
.downArrow {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s 10 alternate;
}

.scrollDown p {
    color: #fff;
    font-size: 12px;
    font-weight: 100;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transform-origin: 90%;
            transform-origin: 90%;
    margin: 0 -4em;
}


/************** About Me ***************************/

.aboutContainer {
    background-color: black;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.aboutContainer h3 {
    font-family: 'Poppins';
    font-size: 2em;
    padding-bottom: 7%;
    font-weight: 100;
}

.aboutContainer h2 {
    font-family: 'Poppins';
    font-size: 2em;
    padding-bottom: 7%;
}

.aboutContainer p {
    font-family: 'lato';
    font-size: 1.1em;
    line-height: 2em;
    width: 70%;
}

.aboutContainer .contact {
    border-radius: 3em;
    width: 11em;
    padding: 2em;
    margin-top: 8em;
    font-size: 0.7em;
}

.mayowa {
    width: 60%;
}

.skill {
    display: flex;
    flex-direction: column;
    padding: 1em;
    color: #fff;
}

.skill p {
    font-size: 0.5em;
    color: #fff;
    text-align: center;
    margin: auto;
}

.skill img {
    margin: auto;
    width: 4em;
}

.skill h5 {
    font-size: 0.8em;
    padding: 0.4em;
    color: #fff;
    text-align: center;
}

.capabilities {
    display: flex;
    flex-direction: column;
}

.capabilities .redLine {
    width: 14em;
}

.stacks h2,
.capabilities h2 {
    color: #fff;
    text-align: center;
    padding-bottom: 0.2em
}

.skillGrid {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 3em;
    row-gap: 3em;
    padding: 1em 0.1em;
    justify-items: center;
}

.stack {
    display: flex;
    width: 40%;
    height: 2em;
    background-color: #FF6F00;
    margin-bottom: 2em;
    border-radius: 1em;
    text-align: center;
}

.redLine {
    display: flex;
    width: 6em;
    background-color: #FF0513;
    height: 0.1em;
    text-align: center;
    margin: auto;
    margin-bottom: 3em;
}


/*
.stackLevel {
    background-color: #A14702;
}*/

.stackName {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #FF6F00;
    height: 100%;
    padding: 0 0.5em;
    text-align: center;
    border-radius: 0.5em;
}

.stackName h5 {
    color: #fff;
    font-family: 'lato';
    text-align: center;
    align-self: center;
    margin: auto;
}

.stackGrid {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(2, auto);
    padding: 1em 2em;
    justify-items: center;
}


/*****Projects Page ***************/

.projectsContainer {
    background-color: rgb(37, 37, 37);
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projectsContainer h3 {
    color: #fff;
    font-family: 'Poppins';
    font-size: 2.5em;
    font-weight: 100;
    text-align: center;
    padding-bottom: 0.4em
}

.verticalSpace {
    padding: 3em;
}

.project {
    width: 15em;
    display: flex;
    flex-direction: column;
    background-color: rgb(37, 47, 37);
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    height: 15em;
    border-radius: 2em;
    align-items: center;
    justify-content: center;
}

.projectDetails {
    background-color: #1C1D26;
    height: 20em;
    margin-top: -5em;
}

.project img {
    border-radius: 2em;
    height: 12em;
}

.project h2 {
    color: #fff;
    font-family: 'Poppins';
    font-size: 1.4em;
    line-height: 1em;
    font-weight: 100;
    text-align: center;
    width: 80%;
}

.project h4 {
    color: #fff;
    font-family: 'Poppins';
    font-size: 1em;
    font-weight: 100;
    text-align: center;
    width: 80%;
}

.project p {
    color: #fff;
    font-family: 'Poppins';
    font-size: 0.5em;
    font-weight: 100;
    text-align: center;
    width: 80%;
}

.projectGrid {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(3, auto);
    grid-gap: 8em 1em;
    justify-items: center;
    padding: 0 3em;
}


/*******Contact Page ********/

.contactContainer {
    background-color: #1C1D26;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
}

.contactContainer h3 {
    font-family: 'Poppins';
    font-size: 2em;
    text-align: center;
    line-height: 1.2em;
    padding-bottom: 1em;
}

.contactContainer p {
    font-family: 'Poppins';
    font-weight: 100;
    line-height: 1.8em;
    width: 60%;
    text-align: center;
}

.contactContainer form {
    text-align: center;
}

.verticalLine {
    display: flex;
    width: 0.1em;
    background-color: #FF0513;
    height: 8em;
    text-align: center;
    margin-bottom: 3%;
}

.contactContainer .contact {
    border-radius: 3em;
    width: 12em;
    padding: 1.6em;
    color: #fff;
    background-color: #FF0513;
    border: none;
    margin: auto;
    font-size: 1em;
}


/***********Footer *****/

.footerContainer {
    background-color: #171717;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerContainer .verticalLine {
    display: flex;
    width: 0.1em;
    background-color: #FF0513;
    height: 4em;
    text-align: center;
    margin: 3% 0;
}

.footerContainer .details {
    font-family: 'Poppins';
    font-size: 0.8em;
    color: #fff !important;
}

.details ul,
li {
    color: #fff !important;
}

.footerLine {
    display: flex;
    height: 0.05em;
    background-color: #9F9F9F;
    width: 100%;
    text-align: center;
    margin-bottom: 3%;
}

.copyright {
    display: flex;
    justify-items: center;
    font-size: 0.8em;
    font-weight: 300;
    font-family: 'inter' sans-serif;
    padding-bottom: 2em;
    height: 5%;
}

.footerContainer .socialIcons img {
    width: 20%;
    text-align: center;
    padding-left: 12px;
}

.contactMe {
    width: 50%;
}

.contactMe input[type='text'] {
    border: none;
    line-height: 1em;
    width: 40%;
    padding: 1em;
    margin-bottom: 1em;
    margin-right: 1em;
    color: #9F9F9F;
}

.contactMe textarea {
    width: 82%;
    padding: 1em;
    margin-right: 1em;
    color: #9F9F9F;
}

@media only screen and (max-width: 900px) {
    .menuWrapper {
        display: inline;
    }
    .menuWrapper ul,
    li {
        font-size: 10px;
    }
    .space {
        padding-right: 20.7px !important;
    }
    .contentWrapper {
        flex-direction: column;
        justify-content: center;
        height: 80vh;
    }
    .orange {
        font-size: 18px;
        padding-bottom: 0;
    }
    .orange,
    .leftAside h1,
    .leftAside h5 {
        text-align: center;
    }
    .leftAside h5 {
        font-size: 14px;
    }
    .contact {
        display: block;
        margin: auto;
    }
    .leftAside h1 {
        font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
        font-style: normal;
        font-feature-settings: normal;
        font-variant: normal;
        font-size: 2.5em;
        line-height: 1em;
        padding-bottom: 12px;
    }
    .rightAside {
        margin-top: 20%;
        padding: 0;
    }
    .rightAside p {
        font-family: 'poppins';
        font-size: 1.2em;
        text-align: center;
    }
    .footer {
        padding-bottom: 1em;
    }
    /***** About Me *******/
    .aboutContainer .contentWrapper {
        flex-direction: column-reverse;
        justify-content: center;
        height: 80vh;
    }
    .aboutContainer h2 {
        padding-top: 1em;
        font-size: 2em;
        text-align: center;
        width: 100%;
    }
    .aboutContainer h3 {
        font-family: 'Poppins';
        font-size: 2em;
        padding-bottom: 7%;
        font-weight: 100;
        text-align: center;
    }
    .aboutContainer p {
        font-size: 1em;
        width: 100%;
        text-align: center;
    }
    .mayowa {
        margin: auto;
        display: block;
        margin-bottom: 2em;
    }
    /******Projects ********/
    .projectGrid {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0 3em;
    }
    .project {
        margin-bottom: 2em;
    }
    .copyright {
        text-align: center;
    }
    .projectDetails {
        margin-top: 2em;
    }
    .contactMe {
        width: 100%;
    }
    .footerLine {
        display: flex;
        height: 0.1em;
        background-color: #9F9F9F;
        width: 100%;
        text-align: center;
        margin-bottom: 3%;
    }
    .downArrow,
    .scrollDown {}
    .stackName {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: #FF6F00;
        height: 100%;
        padding: 0 0.5em;
        text-align: center;
    }
    .stackName h5 {
        color: #fff;
        font-family: 'lato';
        text-align: center;
        align-self: center;
        margin: auto;
        font-size: 0.4em;
    }
    .stackGrid {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
        padding: 0.5em 0.5em;
        justify-items: center;
    }
    .skillGrid {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
        grid-row-gap: 3em;
        row-gap: 3em;
        padding: 1em 0.1em;
        justify-items: center;
    }
    .skillGrid p {
        font-size: 0.3em;
    }
    .copyright {
        text-align: center;
    }
    .contactContainer h3 {
        width: 80%;
    }
    .homeContainer .socialNav {
        padding-right: 6em;
    }
}

@media only screen and (max-width: 360px) {
    .skillGrid {
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
        grid-row-gap: 3em;
        row-gap: 3em;
        padding: 1em 0.1em;
        justify-items: center;
    }
    .copyright {
        text-align: center;
    }
    .aboutContainer {
        padding-top: 2em;
    }
    .aboutContainer .contact {
        display: none;
    }
}
